import { FC, useState } from 'react'

import Debug from 'debug'
import { LongLat } from '@video-wall/core'

import OSM from 'ol/source/OSM'

import TileLayer from 'ol/layer/Tile'
import TileSource from 'ol/source/Tile'
import { fromLonLat } from 'ol/proj'
import { BingMaps, XYZ } from 'ol/source'
import { Map, View } from 'ol'
import type { MapOptions } from 'ol/PluggableMap'

import { PinSize } from '../pin/Pin'
import { useMount, useResized } from '../../lib'

const debug = Debug('vwall:map:ol:BasicMap')

export enum MapType {
    //Open Street Map.
    OSM = 'osm',
    //Google Maps.
    Google = 'google',
    //Bing Maps.
    Bing = 'bing',
}

export enum GoogleMapTileSets {
    TERRAIN = 'p',
    TERRAIN_ONLY = 't',
    SATELLITE_ONLY = 's',
    HYBRID = 'y',
    ROADMAP = 'm',
    ROADS_ONLY = 'h',
    ROADMAP_V2 = 'r',
}

/**
 * @see {@link MapOptions}
 */
export type BasicMapProps = {
    map: Map | undefined

    /**
     * Where the map is centered as a coordinate pair.
     * @default [0, 0]
     */
    center: LongLat
    zoom: number
    mapType: MapType
    googleMapsTileSet?: GoogleMapTileSets
    bingMapsApiKey?: string

    /**
     * An event listener callback which is invoked after the component has mounted
     * and the {@link Map} has been created.
     *
     * @param map the {@link Map} instance object used by this component.
     */
    setPinSize: (pinSize: PinSize) => void
    onInit: (_map: Map) => void
} & Omit<MapOptions, 'target' | 'layers' | 'view' | 'controls'>
// } & Pick<MapOptions, 'pixelRatio'>

export const BasicMap: FC<BasicMapProps> = ({
    map,
    center,
    zoom,
    mapType,
    setPinSize,
    onInit,
    bingMapsApiKey,
    googleMapsTileSet,
    /** @see {@link MapOptions.pixelRatio} */
    // pixelRatio,
    ...mapOptions
}) => {
    const [currZoom, setCurrZoom] = useState(zoom)

    useMount(function basicMapDidMount() {
        debug('Creating new OL map')

        let source: TileSource
        switch (mapType) {
            case MapType.OSM:
                // source = sourceFactory()
                source = new OSM({
                    url: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png',
                })
                break

            case MapType.Google:
                if (googleMapsTileSet) {
                    source = new XYZ({
                        url: `https://maps.google.com/maps/vt/lyrs=${googleMapsTileSet}&x={x}&y={y}&z={z}`,
                        transition: 250,
                        reprojectionErrorThreshold: 0.1,
                        tileSize: [200, 200],
                        tilePixelRatio: 4,
                        imageSmoothing: true,
                    })
                } else {
                    throw new Error('No Google Maps tileset provided')
                }
                break

            case MapType.Bing:
                if (bingMapsApiKey) {
                    source = new BingMaps({
                        key: bingMapsApiKey,
                        imagerySet: 'RoadOnDemand',
                        hidpi: true,
                    })
                } else {
                    throw new Error('No Bing API Key provided')
                }
                break
        }

        const newMap = new Map({
            target: 'map',
            layers: [new TileLayer({ source })],
            view: new View({
                center: fromLonLat(center),
                resolutions: source.getTileGrid()?.getResolutions(),
                zoom: zoom,
            }),
            controls: [],
            ...mapOptions,
        })

        newMap.on('moveend', function (e) {
            const newZoom = newMap.getView().getZoom()
            if (newZoom && currZoom !== newZoom) {
                setCurrZoom(newZoom)
                setPinSize(newZoom < 4 ? 'medium' : 'large')
            }
        })

        onInit(newMap)
    })

    useResized(() => {
        debug('Resized')
        map?.updateSize()
    })

    return <div id="map" className="map" />
}
