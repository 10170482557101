import Debug from 'debug'
import { LongLat, ProductType } from '@video-wall/core'
import { FC, useCallback, useMemo, useState } from 'react'
import { Banner } from '../banner/Banner'
import { CoStarLogo } from '../costar-logo/CoStarLogo'
import { Legend } from '../legend/Legend'
import { AnimatedWebGLMap, MapProps, MapType } from '../map'
import { useLocation } from '../../lib'

const debug = Debug('vwall:routed-app')
/**
 * @see {@link MapProps.devicePixelRatioScalar}
 * @min 1
 */
const DEFAULT_DEVICE_PIXEL_RATIO_SCALAR = 2
const DEFAULT_LAT = 39.0902
const DEFAULT_LNG = -99.7129
const DEFAULT_ZOOM = 6

export const App = (): JSX.Element => {
    const location = useLocation()
    const query = useMemo(() => new URLSearchParams(location.search), [location.search])
    const [selectedBrand, setSelectedBrand] = useState<ProductType | undefined>(undefined)

    const asNumber: (key: string, defaultValue: number, min?: number, max?: number) => number =
        useCallback(
            (key, defaultValue = 0, min, max) => {
                const value = Number.parseFloat(query.get(key) || '')
                if (isNaN(value)) return defaultValue

                if (min != null && value < min) return min
                if (max != null && value > max) return max

                return value
            },
            [query]
        )

    // Feature toggles
    const useImage = useMemo(() => query.get('useImage') != null, [query])

    // View config
    const lat = useMemo(() => asNumber('lat', DEFAULT_LAT, -90, 90), [asNumber])
    const lng = useMemo(() => asNumber('lng', DEFAULT_LNG, -180, 180), [asNumber])
    const zoom = useMemo(() => asNumber('zoom', DEFAULT_ZOOM, 1, 24), [asNumber])
    const dpr = useMemo(() => asNumber('dpr', DEFAULT_DEVICE_PIXEL_RATIO_SCALAR, 0), [asNumber])

    const center: LongLat = [lng, lat]

    const onBrandSelect = useCallback(
        (brand?: ProductType) => {
            if (brand === selectedBrand) {
                setSelectedBrand(undefined)
            } else {
                setSelectedBrand(brand)
            }
        },
        [setSelectedBrand, selectedBrand]
    )

    return (
        <>
            <AnimatedWebGLMap
                center={center}
                zoom={zoom}
                mapType={MapType.Google}
                devicePixelRatioScalar={dpr}
                brandSelected={selectedBrand}
            />
            <Banner useImage={useImage} />
            <Legend
                onBrandSelect={onBrandSelect}
                brandsToDisplay={selectedBrand != null ? [selectedBrand] : undefined}
            />
            <CoStarLogo />
        </>
    )
}
