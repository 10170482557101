import Debug from 'debug'

import ErrorReporter from './error'
import { Promisify } from './types'

/* eslint-disable @typescript-eslint/no-explicit-any */
const FRAME_TIME_60FPS = 16.67

const monitoredFunctions = new Set<string>()
const debug = Debug('vwall:performance')
debug.log = console.log

/**
 * Wraps a function in a timer that calculates how long it took to execute.
 * If the function takes longer than the frame time for 60fps, a warning is logged.
 *
 * @param fn The function to wrap.
 * @returns The wrapped function.
 */
export function checkPerformance<F extends (...args: any[]) => any>(
    fn: F,
    reporter?: ErrorReporter
): F

/**
 * Wraps a function in a timer that calculates how long it took to execute.
 * If the function takes longer than the frame time for 60fps, a warning is logged.
 *
 * @param name The name of the function, used for logging. Defaults to the function's name.
 * @param fn The function to wrap.
 * @returns The wrapped function.
 */
export function checkPerformance<F extends (...args: any[]) => any>(
    name: string,
    fn: F,
    reporter?: ErrorReporter
): F
export function checkPerformance<F extends (...args: any[]) => any>(...args: unknown[]): F {
    /* Handle function overloads */
    if (args.length === 1) {
        // Only a function was passed in
        return checkPerformance(`${(args[0] as F).name}`, args[0] as F, undefined)
    } else if (args.length === 2) {
        // Arguments are a name and a function, or a function and an error reporter
        if (typeof args[1] === 'function') {
            // args are [name, fn]
            return checkPerformance(args[0] as string, args[1] as F, undefined)
        } else {
            // args are [fn, reporter]
            return checkPerformance((args[0] as F).name, args[0] as F, args[1] as ErrorReporter)
        }
    }

    const [funcName, func, reporter] = args as [string, F, ErrorReporter?]
    const warn = reporter
        ? reporter.warn.bind(reporter)
        : (debug('No reporter provided (%s)', funcName), console.warn)

    // const func: F = typeof name === 'string' ? fn as F : name
    // const funcName = typeof name === 'string' ? name : func.name || '<anonymous>'

    // Warn if an already monitored function is being re-created
    if (monitoredFunctions.has(funcName)) {
        warn(
            `checkPerformance got a function named ${funcName} twice, which may indicate that the function is being re-created inside a loop.`
        )
    } else {
        monitoredFunctions.add(funcName)
    }

    /**
     * Wraps a function in a timer that calculates how long it took to execute.
     */
    const withPerformanceCheck: F = ((...args) => {
        // const start = performance.now()
        performance.mark(`${funcName}-start`)
        const result = func(...args)
        performance.mark(`${funcName}-end`)
        // const duration = performance.now() - start

        const measure = performance.measure(
            `${funcName}-duration`,
            `${funcName}-start`,
            `${funcName}-end`
        )

        if (measure) {
            const { startTime, duration } = measure
            if (duration > FRAME_TIME_60FPS) {
                warn(`${funcName} took ${duration}ms and may have caused a frame drop.`, {
                    startTime,
                    duration,
                })
            }
        }

        return result
    }) as F

    // Set the name of the function to the original name
    Object.defineProperty(withPerformanceCheck, 'name', {
        value: `withPerformanceCheck(${funcName})`,
    })

    return withPerformanceCheck as F
}

/**
 * Wraps a function in a timer that calculates how long it took to execute.
 * If the function takes longer than the frame time for 60fps, a warning is logged.
 *
 * @param fn The function to wrap.
 * @returns The wrapped function.
 */
export function checkPerformanceAsync<F extends (...args: any[]) => Promise<any>>(fn: F): F

/**
 * Wraps a function in a timer that calculates how long it took to execute.
 * If the function takes longer than the frame time for 60fps, a warning is logged.
 *
 * @param name The name of the function, used for logging. Defaults to the function's name.
 * @param fn The function to wrap.
 * @returns The wrapped function.
 */
export function checkPerformanceAsync<F extends (...args: any[]) => Promise<any>>(
    name: string,
    fn: F
): F
export function checkPerformanceAsync<F extends (...args: any[]) => Promise<any>>(
    name: F | string,
    fn?: F
): F {
    const func: F = typeof name === 'string' ? (fn as F) : name
    const funcName = typeof name === 'string' ? name : func.name || '<anonymous>'

    if (monitoredFunctions.has(funcName)) {
        console.warn(
            `checkPerformance got a function named ${funcName} twice, which may indicate that the function is being re-created inside a loop.`
        )
    } else {
        monitoredFunctions.add(funcName)
    }

    const withPerformanceCheck: F = (async (...args) => {
        const start = performance.now()
        const result = await func(...args)
        const duration = performance.now() - start

        if (duration > FRAME_TIME_60FPS) {
            console.warn(`${funcName} took ${duration}ms and may have caused a frame drop.`)
        }

        return result
    }) as F

    return withPerformanceCheck as F
}

/**
 * Decorator that turns a synchronous function into an asynchronous one that
 * executes on the next event loop tick.
 *
 * The implementation looks scarier than it is. It's basically just this:
 *
 * ```ts
 * const nextTick = fn =>
 *     (...args) => Promise.resolve().then(() => fn(...args))
 * ```
 *
 * @param fn the function to decorate.
 * @returns The same function that executes on the next event loop tick.
 */
export const nextTick = <F extends (...args: any[]) => any>(fn: F): Promisify<F> => {
    const _delayed: Promisify<F> = ((...args) =>
        Promise.resolve().then(() => fn(...args))) as Promisify<F>

    Object.defineProperty(_delayed, 'name', {
        value: `delayed(${fn.name || '<anonymous>'})`,
    })

    return _delayed
}

/**
 * Decorator that converts `fn` into a function that executes at the next
 * available idle time, resolving with `fn`'s return value. If no idle time is
 * available before `timeout`, the the function is executed "immediately".
 *
 * @param fn The function to decorate.
 * @param timeout Idle timeout in milliseconds. Defaults to `100`.
 *
 * @returns The decorated function.
 */
export const nextIdle = <F extends (...args: any[]) => any>(fn: F, timeout = 100): Promisify<F> => {
    const idleFn: F = ((...args: Parameters<F>) =>
        new Promise<ReturnType<F>>(resolve => {
            requestIdleCallback(
                function idleExec() {
                    resolve(fn(...args))
                },
                { timeout }
            )
        })) as F

    Object.defineProperty(idleFn, 'name', {
        value: `nextIdle(${fn.name || '<anonymous>'})`,
    })

    return idleFn
}
