import { useDebugValue, useRef } from 'react'

import useMount from './useMount'

/**
 * Accepts an callback that acts as an update loop. The callback is invoked
 * before the browser repaints, allowing layout effects the callback applies
 * to be displayed in the next animation frame. Invocation starts after the
 * component using this hook has mounted and is automatically terminated when it
 * dismounts.
 *
 * The return value of the callback acts as a break in the update loop. Returning
 * a truthy value signals that it should be invoked again before the next
 * animation frame. Returning a falsy value breaks this loop, causing updates
 * to cease. Returning a truthy value after a falsy value has no effect - once
 * the loop has been broken, it cannot be re-instated.
 *
 * The callback receives a timestamp as an argument, which is forwarded from
 * {@link requestAnimationFrame}. The exact meaning of this argument seems only
 * semi-standardized in practice. It is usually the time since the page was opened,
 * but is sometimes a high-resolution, sub-millisecond precision double and other
 * times an integer representing milliseconds since the Unix epoch.
 *
 *
 * @param fn The callback function. Gets a high precision timestamp as an argument. Returns `true` to signal that it should be
 * called again as soon as possible, `false` to break the update loop.
 *
 * @see {@link https://developers.google.com/web/updates/2012/05/requestAnimationFrame-API-now-with-sub-millisecond-precision Chrome useAnimationFrame Timestamp Argument}
 * @see {@link https://w3c.github.io/hr-time/ W3C High Resolution Time}
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/window/requestAnimationFrame MDN rAF Docs}
 */
export default function useAnimationFrame(fn: (time: number) => boolean): void {
    const id = useRef<number | undefined>(undefined)
    useDebugValue(id, ({ current }) => (current ? 'Animating' : 'Not Animating'))

    const updateFn: FrameRequestCallback = step => {
        const cont = fn(step)
        // Stop animating if callback returns a falsy value
        id.current = cont ? requestAnimationFrame(updateFn) : undefined
    }

    useMount(function mountAnimationFrame() {
        id.current = requestAnimationFrame(updateFn)

        return () => {
            id.current && cancelAnimationFrame(id.current)
        }
    })
}
