import pinSvg from './img/pin.svg'
// import pinSvg from './img/pin_256x256.svg'

export type PinSize = 'small' | 'medium' | 'large' | 'xlarge'

/**
 * Image data for map pins.
 */
export type PinData = {
    /**
     * URL to the image used for displaying pins.
     */
    src: string

    /**
     * Width and height of the image in pixels. Using a single number sets both
     * the width and height.
     */
    size: number | [width: number, height: number]

    /**
     * How much the width and height for each pin should be scaled by to make the
     * final rendered image a standard size. This is useful for keeping rendered
     * pins a consistent size while allowing for flexibility in image resolution.
     */
    scale: number

    bottom: [widthPercent: number, heightPercent: number]
}

const pins: Record<PinSize, PinData> = {
    small: {
        src: pinSvg,
        size: [340, 370],
        scale: 1 / 17,
        bottom: [0.5, 0.75],
    },
    medium: {
        src: pinSvg,
        size: [340, 370],
        scale: 1 / 14,
        bottom: [0.5, 0.75],
    },
    large: {
        src: pinSvg,
        size: [340, 370],
        // scale: 1 / 10,
        scale: 1 / 12,
        bottom: [0.5, 0.75],
    },
    xlarge: {
        src: pinSvg,
        size: [340, 370],
        scale: 1 / 8,
        bottom: [0.5, 0.75],
    },
}

/**
 * Pin image data for a given size. These images are displayed on the map via
 * the shaders.
 */
export default Object.freeze(pins)
