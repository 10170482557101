import { useCallback, useDebugValue, useState } from 'react'

import useResized from './useResized'

export type Breakpoint = 'xs' | 'lg' | 'xxl'

/**
 * Breakpoint values should be the same as the ones declared in `styles/_variables.scss`.
 */
const breakpointMap: Record<Breakpoint, number> = {
    xs: 0,
    lg: 992,
    xxl: 2000,
}

/**
 * Gets the largest breakpoint that is smaller than `width`.
 *
 * @private
 *
 * @param width
 * @returns
 */
function getBreakpointFor(width: number): Breakpoint {
    let maxBreakpoint: Breakpoint = 'xs'

    for (const [key, value] of Object.entries(breakpointMap)) {
        if (width >= value) {
            maxBreakpoint = key as Breakpoint
        } else {
            break
        }
    }

    return maxBreakpoint
}

/**
 * @returns The largest breakpoint that is smaller than the page's width
 */
const getBreakpoint = () => getBreakpointFor(document.documentElement.getBoundingClientRect().width)

/**
 * Calculates the current breakpoint for the page's width.
 * 
 * This function is equivalent to
```scss
 @media screen and (max-width: $grid-breakpoint) {
  // ...
 }
 ```
 * @returns 
 */
export default function useBreakpoint(): Breakpoint {
    const [breakpoint, setBreakpoint] = useState<Breakpoint>(getBreakpoint)
    useDebugValue(breakpoint)

    const updateBreakpoint = useCallback(() => {
        setBreakpoint(getBreakpoint())
    }, [])

    useResized(updateBreakpoint)

    return breakpoint
}
