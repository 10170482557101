import { useCallback, useDebugValue, useEffect, useState } from 'react'

/**
 * Gets the visibility status of the webpage. That is, this hook exposes state
 * that is updated when the user leaves or comes back to the webpage.
 *
 * @param cb An optional callback function that is invoked when page visibility
 * changes.
 * @returns `true` if the user has the website page open and focused, `false`
 * otherwise.
 */
export default function useVisible(cb?: (visible: boolean) => any): boolean {
    const [visible, setVisible] = useState(true)
    useDebugValue(visible)
    const handleVisibilityChange: () => any = useCallback(
        () => (setVisible(!document.hidden), cb?.(!document.hidden)),
        [setVisible, cb]
    )

    useEffect(() => {
        window.addEventListener('visibilitychange', handleVisibilityChange)
        return () => {
            window.removeEventListener('visibilitychange', handleVisibilityChange)
        }
    }, [handleVisibilityChange])

    return visible
}
