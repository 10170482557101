/* eslint-disable react-hooks/exhaustive-deps */
import { EffectCallback, useEffect } from 'react'

/**
 * Invokes a function a single time after a component is mounted.
 *
 * @param onMount A thunk that contains potentially effectful code. May return a cleanup function.
 */
const useMount = (onMount: EffectCallback): void => useEffect(onMount, [])
export default useMount
