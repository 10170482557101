import { useCallback, useEffect, useRef } from 'react'

import debounce from 'debounce'

const DEBOUNCE_WAIT = 250
type Debounced<P, R = void> = ((arg: P) => R) & {
    clear: () => void
    flush: () => void
}

/**
 * Invokes a callback when the page is resized.
 *
 * @param onResized the callback function
 */
export default function useResized(onResized: (ev: UIEvent) => void): void {
    const onDebouncedResize = useRef<Debounced<UIEvent>>()

    const cleanup = useCallback(function cleanup() {
        if (onDebouncedResize.current) {
            onDebouncedResize.current.clear()
            window.removeEventListener('resize', onDebouncedResize.current)
            onDebouncedResize.current = undefined
        }
    }, [])

    useEffect(
        function useResizedMountDebouncedListenerToResizedEvent() {
            cleanup()
            onDebouncedResize.current = debounce(onResized, DEBOUNCE_WAIT)
            window.addEventListener('resize', onDebouncedResize.current)
            return cleanup
        },
        [onResized, cleanup]
    )
}
