import BaseEvent from 'ol/events/Event'

export type AnimatedPinLayerEventType = 'error'
export type AnimatedPinLayerEventPayload = {
    error: Error
}
export default class AnimatedPinLayerEvent<T extends AnimatedPinLayerEventType> extends BaseEvent {
    type: T
    payload: AnimatedPinLayerEventPayload[T]

    constructor(type: T, payload: AnimatedPinLayerEventPayload[T]) {
        super(type)

        this.type = type
        this.payload = payload
    }
}
