import { useRef } from 'react'

import useMount from './useMount'

/**
 * Hook for {@link setInterval}. Establishes an interval when the component mounts,
 * and clears it when it unmounts.
 *
 * @param handler A function that will be periodically invoked
 * @param delay Time, in milliseconds, the timer waits between function invocations. Defaults to `0`.
 */
export default function useInterval(handler: TimerHandler, delay = 0): void {
    const id = useRef<number | undefined>(undefined)

    useMount(() => {
        id.current = setInterval(handler, delay)

        return () => {
            id.current && clearInterval(id.current)
        }
    })
}
