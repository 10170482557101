import assert from 'assert'

export interface ErrorReporterOptions {
    logToConsole?: boolean
    logToBackend?: boolean
    baseUrl?: string
}

/**
 * Logs errors and warnings to the console and the backend.
 */
export default class ErrorReporter {
    public logToConsole: boolean
    public logToBackend: boolean
    private readonly _baseUrl: string

    constructor(opts: ErrorReporterOptions = {}) {
        const {
            logToConsole = true,
            logToBackend = true,
            baseUrl = process.env.REACT_APP_BACKEND_URL || '/',
        } = opts

        this.logToConsole = logToConsole
        this.logToBackend = logToBackend
        this._baseUrl = baseUrl

        this.error = this.error.bind(this)
        this.warn = this.warn.bind(this)
        this.log = this.log.bind(this)
    }

    public async error(error: Error | string, meta: Record<string, unknown> = {}): Promise<void> {
        const err = error instanceof Error ? error : new Error(error)
        Object.assign(err, meta)

        if (this.logToConsole) {
            console.error(err)
        }

        return this.log('error', err.message, err as unknown as Record<string, unknown>)
    }

    public async warn(warning: string, meta: Record<string, unknown> = {}): Promise<void> {
        if (this.logToConsole) {
            console.warn(warning)
        }

        return this.log('warn', warning, meta)
    }

    public log(message: string): Promise<void>
    public log(message: string, meta: Record<string, unknown>): Promise<void>
    public log(
        level: string | undefined,
        message: string,
        meta: Record<string, unknown>
    ): Promise<void>
    public async log(...args: any[]): Promise<void> {
        // Check which overload is being used by checking the length of the args array.
        if (args.length === 1) {
            const message = args[0]
            const meta = {}
            return this.log(undefined, message, meta)
        } else if (args.length === 2) {
            const [level, message] = args
            const meta = {}
            return this.log(level, message, meta)
        } else {
            const [level, message, meta = {}] = args as [
                level: string | undefined,
                message: string,
                meta: Record<string, unknown> | undefined
            ]

            const payload = { message, stack: this.getStackTrace(), ...meta }
            const url = this.urlFor(level || 'log')

            if (this.logToBackend) {
                try {
                    await fetch(url, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(payload),
                    })
                } catch (err) {
                    // Log errors to the console, otherwise they will be picked up
                    // by window.onerror causing a loop, thereby crashing the app.
                    console.error(err)
                }
            }
        }
    }

    private urlFor(apiMethod: string): string {
        const path = {
            error: 'log/error',
            warn: 'log/warn',
            log: 'log',
        }[apiMethod]
        assert(path, `Invalid api method ${apiMethod}`)

        // Construct the url. Add a slash between the base url and the path
        // if the base url doesn't already end with a slash.
        return this._baseUrl.endsWith('/') ? `${this._baseUrl}${path}` : `${this._baseUrl}/${path}`
    }

    /**
     * Artificially creates a stack trace.
     *
     * This is done by throwing an error and catching it. THe error message
     * and the top stack frame are then removed, and the rest is returned.
     *
     * @returns The stack trace.
     */
    private getStackTrace(): string {
        try {
            throw new Error()
        } catch (err) {
            return (err as Error).stack!.split('\n').slice(2).join('\n')
        }
    }
}
