/* eslint-disable import/no-webpack-loader-syntax */
import assert from 'assert'
import Debug from 'debug'
import { HSV, PRODUCT_COLORS, ProductType } from '@video-wall/core'

// OL imports
import type { Feature } from 'ol'
import { FrameState } from 'ol/PluggableMap'
import type { Geometry } from 'ol/geom'
import { Layer } from 'ol/layer'
import { Point } from 'ol/geom'
import RenderEventType from 'ol/render/EventType'
import { Options as VectorLayerOptions } from 'ol/layer/BaseVector'
import type VectorSource from 'ol/source/Vector'
import WebGLPointsLayerRenderer, {
    Options as WebGLPointsLayerRendererOptions,
} from 'ol/renderer/webgl/PointsLayer'

// local imports
import AnimatedPinLayerEvent from './AnimatedPinLayerEvent'
import ErrorReporter from '../../../lib/error'
import { PinData } from '../pin'
import { checkPerformance, nextIdle } from '../../../lib'

// Shaders
import fragmentShader from '!!raw-loader!./fragment.frag'
import vertexShader from '!!raw-loader!./vertex.vert'
import RenderEvent from 'ol/render/Event'
import BaseEvent from 'ol/events/Event'

const debug = Debug('vwall:map:ol:anim:AnimatedPinLayer')
debug.log = console.log

const MIN_DROP_DISTANCE = -90
const MAX_DROP_DISTANCE = 90

export interface AnimatedPinLayerOptions<S extends VectorSource<any>>
    extends VectorLayerOptions<S> {
    /**
     * Configures the marker image displayed on the map.
     */
    marker: PinData

    /**
     * How far pin should drop when they first appear, measured in degrees (latitude).
     * Must be between -90 and 90 degrees.
     * @default 20
     */
    dropDistance?: number

    /**
     * How long it takes for pins to drop in when they first appear, measured in seconds.
     * Must be strictly positive.
     * @default 1
     */
    dropTime?: number

    /**
     * Delay between pin touchdown and pin fade out. Measured in seconds.
     *
     * @default 1
     */
    stickTime?: number

    /**
     * The ratio of the ping ellipse's width to its height. Values over/under 1
     * result in long/tall ovals respectively, while 1 results in a circle. Must
     * be strictly positive.
     *
     * @default 1.75
     */
    pingRadiusRatio?: number

    /**
     * Adjusts the max size of the ping ellipse. The max width/height of the
     * ping is `(pingRadiusScale * pingRadiusRatio, pingRadiusScale * 1)`.
     *
     * @default 0.3
     */
    pingRadiusScale?: number

    reporter?: ErrorReporter

    /**
     * If provided, only pins of this type will be displayed. Otherwise, all
     * brands will be displayed.
     *
     * @default undefined
     */
    brandSelected?: ProductType
}

/**
 * A {@link Layer} for rendering property view events as pins. Pins are rendered
 * onto a canvas using WebGL.
 *
 * Note that the terms Pin, Marker, and Feature are used interchangeably. Pins (and markers)
 * are manifested as {@link Feature Features}, which are stored and managed by a {@link VectorSource}.
 *
 * @see {@link https://openlayers.org/workshop/en/webgl/animated.html "Animating Points" Workshop}
 * @see {@link https://openlayers.org/en/latest/apidoc/module-ol_layer_Layer-Layer.html Layer Docs}
 * @see {@link https://www.khronos.org/opengl/wiki/Built-in_Variable_(GLSL) GLSL Built-in Variables}
 */
export default class AnimatedPinLayer<S extends VectorSource<any>> extends Layer<
    S,
    WebGLPointsLayerRenderer
> {
    /** @see {@link AnimatedPinLayerOptions#brandSelected} */
    public brandSelected?: ProductType

    private img: HTMLImageElement
    private size: [width: number, height: number]
    private _pinBottom: [widthPercent: number, heightPercent: number]
    private startTime: number

    /** @see {@link AnimatedPinLayerOptions#dropDistance} */
    private _dropDistance: number

    /** @see {@link AnimatedPinLayerOptions#dropTime} */
    private _dropTime: number

    /** @see {@link AnimatedPinLayerOptions#stickTime} */
    private _stickTime: number

    /** @see {@link AnimatedPinLayerOptions#pingRadiusRatio} */
    private _pingRadiusRatio: number

    /** @see {@link AnimatedPinLayerOptions#pingRadiusScale} */
    private _pingRadiusScale: number

    /** Milliseconds */
    private _sweepDelay: number

    /**
     * Pin time to live, in seconds. How long the pin lasts on the map, from when it first
     * drops in to when it finally fades out.
     */
    private _ttl!: number

    constructor(opts: AnimatedPinLayerOptions<S>) {
        super(opts)
        const {
            marker,
            dropDistance = 20,
            dropTime = 1,
            stickTime = 1,
            pingRadiusRatio = 1.75,
            pingRadiusScale = 0.3,
            reporter,
            brandSelected,
        } = opts

        this.startTime = Date.now()

        // Set image settings
        const { src, size, scale, bottom } = marker
        this.img = new Image()
        this.img.src = src
        this.size = typeof size === 'number' ? [size, size] : size
        this.size = this.size.map(s => s * scale) as [number, number]
        this._pinBottom = bottom
        this._sweepDelay = 500

        // Animation settings
        assert(
            dropDistance >= MIN_DROP_DISTANCE && dropDistance <= MAX_DROP_DISTANCE,
            'Drop distance must be between -90 and 90 degrees.'
        )
        assert(dropTime > 0, 'Drop time must be strictly positive.')
        assert(stickTime > 0, 'Drop time must be strictly positive.')
        assert(pingRadiusRatio > 0, 'Ping radius ratio must be strictly positive.')
        assert(pingRadiusScale > 0, 'Ping radius scale must be strictly positive.')

        this.brandSelected = brandSelected
        this._dropDistance = dropDistance
        this._dropTime = dropTime
        this._stickTime = stickTime
        this._pingRadiusRatio = pingRadiusRatio
        this._pingRadiusScale = pingRadiusScale
        this.updatePinTtl()
        this.getHSVForProductType = this.getHSVForProductType.bind(this)
        this.sweepBatch = nextIdle(
            checkPerformance('sweepBatch', this.sweepBatch.bind(this), reporter)
        )
        this.prerender = this.prerender.bind(this)
        this.sweep = this.sweep.bind(this)

        // this.on('prerender', this.prerender)
    }

    /**
     * How far pin should drop when they first appear, measured in degrees (latitude).
     * Must be between -90 and 90 degrees.
     */
    public get dropDistance(): number {
        return this._dropDistance
    }

    public set dropDistance(distance: number) {
        assert(
            distance >= MIN_DROP_DISTANCE && distance <= MAX_DROP_DISTANCE,
            'Drop distance must be between -90 and 90 degrees.'
        )
        this._dropDistance = distance
    }

    /**
     * How long it takes for pins to drop in when they first appear, measured in seconds.
     * Must be strictly positive.
     */
    public get dropTime(): number {
        return this._dropTime
    }

    public set dropTime(time: number) {
        assert(time > 0, 'Drop time must be strictly positive.')
        this._dropTime = time
        this.updatePinTtl()
    }

    /**
     * The delay between pin touchdown and pin fade out. Measured in seconds.
     * Must be strictly positive.
     */
    public get stickTime(): number {
        return this._stickTime
    }

    public set stickTime(time: number) {
        assert(time > 0, 'Drop time must be strictly positive.')
        this._stickTime = time
        this.updatePinTtl()
    }

    /**
     * How long, in seconds, a pin stays on the map for.
     */
    public get ttl(): number {
        return this._ttl
    }

    /**
     * Removes pins that have faded off the map from the underlying source.
     *
     * @note This method is a bottleneck. Optimize it as much as possible.
     *
     * @returns The number of removed features.
     *
     * @see Feature
     * @see VectorSource
     *
     */
    public async sweep(): Promise<number> {
        /**
         * Controls batch size growth with respect to the number of total
         * features.
         */
        const SWEEP_BATCH_SCALE_FACTOR = 0.75
        const staleTime = Date.now() - this._sweepDelay - this._ttl * 1000
        const source = this.getSource()
        // Short circuit if there is no source or no features
        // TODO: source.isEmpty() breaks when not using an RTree. This is a
        // bug in openlayers.
        //
        // Update: Bug has been patched, should be in next release.
        // bug report: https://github.com/openlayers/openlayers/issues/13366
        // PR: https://github.com/openlayers/openlayers/pull/13373
        if (!source) return 0
        // if (!source || source.isEmpty()) return 0

        // Mark old features for removal
        const marked: Feature<Geometry>[] = []
        source.forEachFeature(
            // TODO: May need to assert timestamp, check if it's a date object
            function checkIfFeatureIsStale(feature) {
                feature.get('timestamp') < staleTime && marked.push(feature)
            }
        )

        const numRemoved = marked.length
        // Both batch sizes and # of batches grow w/ number of marked pins
        // (Check out this graph below, focus on ~500 to ~1,000 range)
        // https://www.desmos.com/calculator/umsn4uvbmx
        const batchSize = Math.ceil(Math.pow(numRemoved, SWEEP_BATCH_SCALE_FACTOR))

        // Remove marked features
        for (let i = 0; i < numRemoved; i += batchSize) {
            const spanEnd = Math.min(i + batchSize, numRemoved)

            // DO NOT USE setImmediate()!!!! https://stackoverflow.com/a/27648394
            await Promise.resolve().then(() => this.sweepBatch(i, spanEnd, source, marked))
        }

        return numRemoved
    }

    /**
     *
     * @returns A Renderer for this layer.
     *
     * @see {@link WebGLPointsLayerRenderer WebGL Renderer}
     * @see {@link WebGLPointsLayerRendererOptions WebGL Renderer Options Object}
     */
    createRenderer(): WebGLPointsLayerRenderer {
        const renderer = new WebGLPointsLayerRenderer(this, {
            // CSS class name for the canvas element
            className: this.getClassName(),

            /* Values that do not vary from feature to feature. May vary across frames.
             * Available in both fragment and vertex shaders.
             *
             * Uniform values are updated before each and every frame, using the
             * data provided here. Functions will be called, and the uniform will be
             * set to the return value. Note that these functions should offload as
             * much of the math/calculation work as possible to the GPU in order to
             * optimize performance. This is important - otherwise the map may be
             * too slow (there are a _lot_ of pins).
             */
            uniforms: {
                /**
                 * Current time as the number of seconds since the program started.
                 */
                u_time: () => (Date.now() - this.startTime) / 1000,
                u_texture: this.img,
                u_size: this.size,
                u_pin_drop_distance: this._dropDistance,
                u_pin_drop_time: this._dropTime,
                u_pin_stick_time: this._stickTime,
                u_pin_ttl: this.ttl,
                u_pin_bottom_location: this._pinBottom,
                // u_ping_radius_ratio: 3.0 / 2.0,
                u_ping_radius_ratio: this._pingRadiusRatio,
                u_ping_radius_scalar: this._pingRadiusScale,
                u_brand_selected: () => (this.brandSelected == null ? -1 : this.brandSelected),
            },

            // Values that are different for each feature. Only available in the vertex
            // shader. Unfortunately, attributes may only be numbers (accessible as floats)
            attributes: [
                {
                    name: 'brand',
                    callback: (feature, { productType }) => productType as ProductType,
                },
                {
                    name: 'timestamp',
                    callback: (feature: Feature<Point>) => {
                        const timestamp = feature.get('timestamp')
                        assert(timestamp != null, 'Feature has no timestamp attribute.')
                        let ms: number

                        if (timestamp instanceof Date) {
                            ms = timestamp.valueOf()
                        } else if (typeof timestamp === 'number') {
                            ms = timestamp
                        } else {
                            throw new TypeError(
                                `Expected timestamp attribute to be a Date or number, got ${timestamp}.`
                            )
                        }

                        const dt = (ms - this.startTime) / 1000
                        return dt
                    },
                },
                {
                    name: 'hue',
                    callback: (feature, { productType }) =>
                        this.getHSVForProductType(productType)[0],
                },
                {
                    name: 'saturation',
                    callback: (feature, { productType }) =>
                        this.getHSVForProductType(productType)[1],
                },
                {
                    name: 'value',
                    callback: (feature, { productType }) =>
                        this.getHSVForProductType(productType)[2],
                },
            ],
            // https://www.khronos.org/opengl/wiki/Vertex_Shader
            vertexShader,
            // https://www.khronos.org/opengl/wiki/Fragment_Shader
            fragmentShader,
        } as WebGLPointsLayerRendererOptions)

        // renderer.addEventListener(RenderEventType.PRERENDER, )
        // renderer.addEventListener(RenderEventType.PRERENDER, prerender as any)

        return renderer
    }

    /**
     * Used by {@link BaseVectorLayer}, even though this is not part of the {@link LayerRenderer}
     * api. Should never be called. Implementing this allows for less confusing error messages.
     *
     * @param frameState
     */
    public renderDeclutter(frameState: FrameState): void {
        throw new Error('AnimatedPinLayer does not implement this method.')
    }

    protected disposeInternal(): void {
        /* NOTE: if hasRenderer() returns `true`, `getRenderer()` is guaranteed
         * to exist. However, OpenLayers types are iffy and need to be worked around.
         */
        if (this.hasRenderer()) {
            this.getRenderer()?.dispose()
        }
        super.disposeInternal()
    }

    /**
     * @note This method was originally created as a potential solution to pin
     * image aliasing (rough edges). As of now, this method isn't even listening
     * to the prerender method. It remains here just in case.
     *
     * @link [WebGL
     * Textures](https://webglfundamentals.org/webgl/lessons/webgl-3d-textures.html);
     * Really helpful for understanding how to handle textures in WebGL
     */
    private prerender(event: RenderEvent) {
        // debug('called')
        const gl = event.context as WebGLRenderingContext
        assert(gl != null, 'No WebGL context.')
        const { width, height } = this.img
        // https://developer.mozilla.org/en-US/docs/Web/API/WebGLRenderingContext/sampleCoverage
        // gl.enable(gl.SAMPLE_COVERAGE)
        // gl.sampleCoverage(0.5, false)

        /* Mipmaps can only be generated for images whose width and heigh are
         * both powers of two. Images that do not meet this criteria are called
         * NPOT (non-power of two). See the below link for details.
         * https://www.khronos.org/opengl/wiki/NPOT_Texture#:~:text=An%20NPOT%20Texture%20is%20a,restricted%20to%20powers%20of%20two.
         */
        if (this.isPowerOfTwo(width) && this.isPowerOfTwo(height)) {
            debug('Image dimensions are powers of two, generating mipmaps')
            gl.generateMipmap(gl.TEXTURE_2D)
        } else {
            /* only NEAREST and LINEAR are valid for mag filter
             * https://webglfundamentals.org/webgl/lessons/webgl-3d-textures.html#:~:text=For%20TEXTURE_MAG_FILTER%20only%20NEAREST%20and%20LINEAR%20are%20valid%20settings.
             */
            debug('Image is NPOT, using filtering')
            gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR)
            gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR)
        }

        // if (gl instanceof WebGL2RenderingContext) {
        //     console.log(gl.getRenderbufferParameter(gl.RENDERBUFFER, gl.RENDERBUFFER_SAMPLES))
        // }
    }

    private isPowerOfTwo(x: number) {
        return (x & (x - 1)) === 0
    }

    /**
     * Sweeps a section of a source, marking {@link Feature Features} that should
     * be removed. These are added to the `marked` list.
     *
     * @param start Batch start index
     * @param end Batch end index
     * @param source Source being swept
     * @param marked Shared list of feature within `source` marked for removal. Features in the batch that need to be removed are added to this list.
     *
     * @see {@link sweep}
     * @see {@link VectorSource}
     */
    private sweepBatch(start: number, end: number, source: S, marked: Feature<Geometry>[]): void {
        for (let j = start; j < end; j++) {
            try {
                source.removeFeature(marked[j])
            } catch (err) {
                this.dispatchEvent(new AnimatedPinLayerEvent('error', err as Error))
            }
        }
    }

    /**
     *
     * @param productType
     * @returns the HSV color for the given product type
     */
    private getHSVForProductType(productType: unknown): HSV {
        assert(productType != null, 'Feature has no productId attribute')
        assert(
            (productType as string | number | symbol) in ProductType,
            `Feature has an invalid productId attribute: ${productType}`
        )

        const { hsv } = PRODUCT_COLORS[productType as ProductType]
        assert(hsv, `Product type ${productType} has no hsv color`)

        return hsv
    }

    private updatePinTtl(): void {
        this._ttl = 2 * this._dropTime + this._stickTime
    }
}
