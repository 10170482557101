import { FC, useEffect, useMemo, useRef, useState } from 'react'

import assert from 'assert'
import Debug from 'debug'
import type { Feature } from 'ol'
import { LongLat, ProductType } from '@video-wall/core'
import { Map } from 'ol'
import { Point } from 'ol/geom'
import VectorSource from 'ol/source/Vector'

import AnimatedPinLayer from './AnimatedPinLayer'
import AnimatedPinLayerEvent from './AnimatedPinLayerEvent'
import { PinSize } from '../../pin/Pin'
import { BasicMap, GoogleMapTileSets, MapType } from '..'
import {
    checkPerformance,
    useAnimationFrame,
    useInterval,
    usePinDispatch,
    useVisible,
} from '../../../lib'
// import { sortVectorSourceInPlace } from './util'
import pins from '../pin'
import '../Map.scss'
import { useErrorReporterContext } from '../../../lib/error'
// import { Layer } from 'ol/layer'

const debug = Debug('vwall:map:ol:anim:AnimatedWebGLMap')
debug.log = console.log
Debug.enable(process.env.DEBUG || process.env.REACT_APP_DEBUG || '')
const url = process.env.REACT_APP_BACKEND_URL || '/'

export type MapProps = {
    center: LongLat
    zoom: number
    mapType: MapType
    /**
     * Controls the resolution of map pins. Higher numbers look better at the
     * cost of performance.
     *
     * @see {devicePixelRatio}
     * @default 1
     * @min 1
     */
    devicePixelRatioScalar?: number

    /**
     * If provided, only pins of this type will be displayed. Otherwise, all
     * brands will be displayed.
     *
     * @default undefined
     */
    brandSelected?: ProductType
}

// TODO: Move these magic numbers into a config somewhere
const PIN_DELAY = 2000
const SWEEP_INTERVAL = 1000

/**
 * NOTE: Callback functions are intentionally not anonymous or arrow functions.
 * This is to make reading performance traces easier. Please do not change this.
 */
export const AnimatedWebGLMap: FC<MapProps> = ({
    center,
    zoom,
    mapType,
    devicePixelRatioScalar = 1,
    brandSelected,
}) => {
    const [pinSize, setPinSize] = useState<PinSize>('large')
    const map = useRef<Map | undefined>(undefined)
    const layer = useRef<AnimatedPinLayer<VectorSource<Point>> | undefined>(undefined)
    const pinChunk = usePinDispatch(url, PIN_DELAY)
    const marker = useMemo(() => pins[pinSize], [pinSize])
    const reporter = useErrorReporterContext()
    const pixelRatio = useMemo(
        () => (
            assert(
                devicePixelRatioScalar >= 1,
                `Invalid devicePixelRatioScalar ${devicePixelRatioScalar}. must be greater than or equal to 1.`
            ),
            devicePixelRatio * devicePixelRatioScalar
        ),
        [devicePixelRatioScalar]
    )

    // Where pins are stored
    const [source] = useState(
        () => new VectorSource<Point>({ features: [], useSpatialIndex: false })
    )

    /**
     * Inserts new pins received from the backend into the {@link VectorSource}.
     * These pins will then be rendered onto the map.
     *
     * Note that `useMemo` is being used instead of `useCallback` so that eslint
     * can determine callback dependencies, which breaks because of the
     * {@link checkPerformance} decorator.
     */
    const addFeaturesToSource = useMemo(
        () =>
            // eslint-disable-next-line @typescript-eslint/no-shadow
            checkPerformance(function addFeaturesToSource(newPins: Feature<Point>[]) {
                source.addFeatures(newPins)
            }, reporter),
        [source, reporter]
    )

    const pinLayer = useRef<AnimatedPinLayer<VectorSource<Point>> | undefined>(undefined)

    const onInit = (_map: Map) => {
        map.current = _map
    }

    // When marker changes, remove the layer and create a new layer with
    // new marker
    useEffect(() => {
        const createPinLayer = () => {
            // TODO: Move these into a config
            const _layer = (layer.current = new AnimatedPinLayer({
                source,
                marker,
                dropDistance: 40,
                dropTime: 0.42,
                stickTime: 0.25,
                pingRadiusScale: 0.3,
                pingRadiusRatio: 2,
                reporter,
                brandSelected,
            }))
            _layer.addEventListener('error', ev => {
                if (ev instanceof AnimatedPinLayerEvent) {
                    reporter.error((ev as AnimatedPinLayerEvent<'error'>).payload)
                } else {
                    reporter.error(
                        Object.assign({}, ev, new Error('AnimatedPinLayer emitted an event'))
                    )
                }
            })

            return _layer
        }

        // An AnimatedPinLayer already exists and is on the map when any of
        // these dependencies change, so it needs to be removed before the new
        // layer is added.
        if (pinLayer.current) {
            map.current?.removeLayer(pinLayer.current)
            pinLayer.current.dispose()
        }

        pinLayer.current = createPinLayer()

        map.current?.addLayer(pinLayer.current)
    }, [marker, source, reporter])

    // Pass updates to brandSelected to layer
    useEffect(() => {
        if (layer.current) {
            layer.current.brandSelected = brandSelected
        }
    }, [brandSelected])

    // When new pins are received from backend, convert them to features and add
    // them to the source
    useEffect(
        function updatePinSourceWithChunk() {
            if (pinChunk) addFeaturesToSource(pinChunk)
        },
        [pinChunk, addFeaturesToSource]
    )

    // Periodically sweep pins that have faded off the map
    useInterval(function sweepStalePins() {
        pinLayer.current?.sweep().then(count => {
            if (count > 0)
                debug('%d features removed, %d remaining', count, source.getFeatures().length)
        })
    }, SWEEP_INTERVAL)

    // Force re-rendering, otherwise the map only re-renders on zoom/pan
    useAnimationFrame(function reRenderMap(step) {
        map.current?.render()
        return true
    })

    return (
        <BasicMap
            map={map.current}
            center={center}
            zoom={zoom}
            mapType={mapType}
            onInit={onInit}
            setPinSize={setPinSize}
            googleMapsTileSet={GoogleMapTileSets.ROADMAP_V2}
            pixelRatio={pixelRatio}
        />
    )
}
