import React, { FC, useMemo } from 'react'

/* <?xml version="1.0" encoding="UTF-8"?> */

/**
 * How much to dim unselected pins. Percentage between 0 and 100, where 100
 * is full brightness.
 */
const UNSELECTED_BRIGHTNESS = 75
const TRANSITION_DURATION = '100ms'

export type ColorCode = `#${Capitalize<string>}`
export type PinSize = 'small' | 'medium' | 'large' | 'xlarge'
// fill="#FFB400"
export type PinProps = {
    /**
     * Pin fill color. Must be a hex color code
     *
     * @example "#FFB400"
     */
    color: ColorCode

    /**
     * @default "#000000"
     */
    borderColor?: ColorCode

    /**
     * Width of border in pixels.
     *
     * @default 1
     */
    borderWidth?: number

    /**
     * Pin drop in animation duration.
     *
     * May be a [clock value
     * string](https://developer.mozilla.org/en-US/docs/Web/SVG/Content_type#clock-value)
     * or a number of seconds. If omitted, no animation occurs.
     *
     * @example "1s"
     */
    pinDropTime?: `${number}s` | number

    /**
     * How big the pin should be.
     *
     * @default "medium"
     */
    size?: PinSize

    /**
     * @default true
     */
    selected?: boolean

    onClick?: () => void
}

const usePinSize = (size: PinSize): [width: number, height: number] =>
    useMemo(() => {
        switch (size) {
            case 'small':
                return [18, 33]
            case 'medium':
                return [22, 52]
            case 'large':
                return [28, 66]
            case 'xlarge':
                return [33, 79]
        }
    }, [size])

/**
 * An animated pin placed on a GoogleMaps map.
 * @param props
 * @returns
 */
export const Pin: FC<PinProps> = ({
    color,
    borderColor = '#000000',
    borderWidth = 1,
    pinDropTime,
    size = 'medium',
    selected = true,
    onClick,
}) => {
    const yPos = -6881.0
    const xPos = -123.0
    const [width, height] = usePinSize(size)
    // const timing = {
    //   pinDrop: "1s"
    // }

    return (
        <svg
            width={`${width}px`}
            height={`${height}px`} // was 33px
            viewBox="-2 0 24 35"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            onClick={onClick}
        >
            <style>
                {`
                    #CoStar-UI-Kit {
                        cursor: ${onClick ? 'pointer' : 'default'};
                        transition: all ${TRANSITION_DURATION} ease-in-out;
                    }

                    #CoStar-UI-Kit:hover {
                        transform: scale(1.1);
                        /* centering y at 80% to scale up/out from the pointy part of the pin */
                        transform-origin: 50% 80%;
                    }

                    #Shape {
                        transition: filter ${TRANSITION_DURATION} ease-in-out;
                    }
                `}
            </style>
            {/* <!-- Generator: Sketch 49.3 (51167) - http://www.bohemiancoding.com/sketch --> */}
            {/* <title>hover</title>
            <desc>Created with Sketch.</desc> */}
            <defs>
                <radialGradient
                    cx="50%"
                    cy="49.6469329%"
                    fx="50%"
                    fy="49.6469329%"
                    r="165.201786%"
                    gradientTransform="translate(0.500000,0.496469),scale(0.304348,1.000000),scale(1.000000,0.274581),translate(-0.500000,-0.496469)"
                    id="radialGradient-1"
                >
                    <stop stopColor="#000000" stopOpacity="0.537760417" offset="0%"></stop>
                    <stop stopColor="#000000" stopOpacity="0" offset="100%"></stop>
                </radialGradient>
            </defs>
            <g id="CoStar-UI-Kit" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform={`translate(${xPos}, ${yPos})`} fillRule="nonzero" id="PINS">
                    {pinDropTime && (
                        <animateTransform
                            attributeName="transform"
                            begin="0s"
                            dur={pinDropTime}
                            type="translate"
                            from={`${xPos} ${yPos - 10}`}
                            to={`${xPos} ${yPos}`}
                            repeatCount="indefinite"
                        />
                    )}
                    <g transform="translate(37.000000, 3053.000000)">
                        <g id="hover" transform="translate(86.000000, 3828.000000)">
                            <g id="Group-98">
                                <ellipse
                                    id="Oval"
                                    fill="url(#radialGradient-1)"
                                    cx="10"
                                    cy="30.4"
                                    rx="9.2"
                                    ry="2.8"
                                ></ellipse>
                                <path
                                    d="M0.8,11 C0.6,10.6 0.7,10 1,9.6 L9.4,1.2 C9.7,0.9 10.2,0.9 10.5,1.2 L19,9.6 C19.3,9.9 19.4,10.5 19.2,11 L10.5,29.6 C10.3,30 10,30 9.8,29.6 L0.8,11 Z"
                                    id="Shape"
                                    stroke={borderColor}
                                    strokeWidth={`${borderWidth}px`}
                                    // fill="#FFB400"
                                    fill={color}
                                    filter={
                                        selected
                                            ? undefined
                                            : `brightness(${UNSELECTED_BRIGHTNESS}%)`
                                    }
                                ></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}
