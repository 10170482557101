import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.scss'
// import { App } from './components/app/App'
import { App } from './components/app/App'
import reportWebVitals from './reportWebVitals'
import ErrorReporter, { ErrorReporterProvider } from './lib/error'
import { ErrorHandler } from './components/error-handler/ErrorHandler'

const container = document.getElementById('root')
if (!container) {
    throw new Error('Could not find #root container node')
}
if (!(container instanceof HTMLElement)) {
    throw new TypeError('Found #root container, but it is not an HTMLElement.')
}
const root = ReactDOM.createRoot(container)

root.render(
    <React.StrictMode>
        <ErrorReporterProvider value={new ErrorReporter()}>
            <ErrorHandler>
                <App />
            </ErrorHandler>
        </ErrorReporterProvider>
    </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
