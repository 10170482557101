import React, { FC, useCallback, useMemo } from 'react'
import { PRODUCT_COLORS, PRODUCT_DISPLAY_NAMES, ProductType, rgb2hex } from '@video-wall/core'
import { Pin, PinProps, PinSize } from '../pin/Pin'
import { useBreakpoint } from '../../lib'

import './Legend.scss'

export type LegendProps = {
    /**
     * Callback that is invoked when a pin is clicked.
     *
     * Clicking a pin will select it, causing only pins from that brand to be
     * displayed.
     */
    onBrandSelect?: (brand: ProductType) => void

    /**
     * List of selected brands that are being displayed on the map.
     *
     * Realistically this list will contain only one element, but it's being
     * implemented as a list to allow for future expansion.
     *
     * If no list is provided, all brands are selected.
     */
    brandsToDisplay?: ProductType[]
}

export const Legend: FC<LegendProps> = ({ onBrandSelect, brandsToDisplay }) => {
    const breakpoint = useBreakpoint()
    const pinSize: PinSize = useMemo(
        () => (breakpoint === 'xxl' ? 'medium' : 'small'),
        [breakpoint]
    )

    /**
     * Note to self: For a brand or pin to be "selected" means that pins for
     * that brand are being displayed on the map.
     */
    const isSelected = useCallback(
        (brand: ProductType) => (!brandsToDisplay ? true : brandsToDisplay.includes(brand)),
        [brandsToDisplay]
    )

    const productRows = useMemo(
        () =>
            Object.keys(ProductType)
                .map(key => Number(key))
                .filter(key => !isNaN(key))
                .map(key => (
                    <ProductRow
                        product={key as ProductType}
                        size={pinSize}
                        key={key}
                        selected={isSelected(key as ProductType)}
                        onClick={onBrandSelect}
                    />
                )),
        [pinSize, isSelected, onBrandSelect]
    )

    return (
        <div id="legend">
            {/* {(Object.keys(ProductType) as Array<ProductType>).map(product =>)} */}
            {productRows}
        </div>
    )
}

type ProductRowProps = {
    product: ProductType
    onClick?: (product: ProductType) => void
} & Pick<PinProps, 'size' | 'selected'>

const ProductRow: FC<ProductRowProps> = ({ product, onClick, ...rest }) => {
    const color = useMemo(() => rgb2hex(PRODUCT_COLORS[product].rgb), [product])
    const onPinClick = useMemo(
        () => (onClick ? () => onClick(product) : undefined),
        [product, onClick]
    )

    const productTypeId = useMemo(
        () => PRODUCT_DISPLAY_NAMES[product].toLowerCase().replace(/\s/g, '-'),
        [product]
    )

    /* FIXME: Need feedback. The pin svg is kinda tiny, so clicking it is hard.
     * Should clicking the row be the same as clicking the pin? For now, I'm
     * choosing yes.
     */
    return (
        <div
            id={`product-row-${productTypeId}`}
            className="product-row"
            data-selected={rest.selected}
            onClick={onPinClick}
        >
            <Pin
                color={color}
                borderColor={'#FFF'}
                borderWidth={2}
                onClick={onPinClick}
                {...rest}
            />
            <span>{PRODUCT_DISPLAY_NAMES[product]}</span>
        </div>
    )
}
