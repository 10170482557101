import { FC, useCallback, useEffect } from 'react'

import { App } from '../app/App'
import { ErrorBoundary } from 'react-error-boundary'
import { useErrorReporterContext } from '../../lib/error/ErrorReporter.context'

type ErrorHandlerProps = {
    children: JSX.Element | Array<JSX.Element>
}

export const ErrorHandler: FC<ErrorHandlerProps> = props => {
    const reporter = useErrorReporterContext()

    const windowErrorHandler = useCallback(
        function onWindowError(event: ErrorEvent): void {
            const { error, lineno, colno, filename } = event
            reporter.error(error, { lineno, colno, filename })
        },
        [reporter]
    )

    useEffect(() => {
        window.addEventListener('error', windowErrorHandler)

        return () => {
            window.removeEventListener('error', windowErrorHandler)
        }
    }, [windowErrorHandler])

    return (
        <ErrorBoundary FallbackComponent={App} onError={reporter.error}>
            {props.children}
        </ErrorBoundary>
    )
}
