import React from 'react'

import ErrorReporter from './ErrorReporter'

export const ErrorReporterContext = React.createContext<ErrorReporter>(new ErrorReporter())

/**
 * Exposes a global {@link ErrorReporter} instance. Used for sending logs to the
 * server.
 *
 * @see {@link ErrorReporter}
 */
export const useErrorReporterContext = (): ErrorReporter => React.useContext(ErrorReporterContext)
export const ErrorReporterProvider = ErrorReporterContext.Provider
export const ErrorReporterConsumer = ErrorReporterContext.Consumer
