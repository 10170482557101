import { useCallback, useDebugValue, useEffect, useMemo, useRef, useState } from 'react'

import Debug from 'debug'
import { LongLat, PropertyEvent, SEARCHES_BATCH_KEY } from '@video-wall/core'
import io, { Socket } from 'socket.io-client'

import { Feature } from 'ol'
import { Point } from 'ol/geom'
import { fromLonLat } from 'ol/proj'

import useMount from './useMount'
import useVisible from './useVisible'
import { checkPerformance, nextIdle, nextTick } from '../performance'
import { useErrorReporterContext } from '../error'

const debug = Debug('vwall:map:ol:usePinBuffer')
debug.log = console.log

/**
 * Establishes a socket.io connection to the backend and listens for new property
 * event chunks.
 *
 * @param uri The backend's URI where the connection should be opened.
 * @param delay Buffer delay, in ms. Defaults to `0`.
 *
 * @returns The most recently emitted pin event chunk, which is updated on each
 * push from the backend.
 */
export default function usePinDispatch(uri: string, delay = 0): Feature<Point>[] | undefined {
    const socket = useRef<Socket | undefined>(undefined)
    const [status, setStatus] = useState<
        'disconnected' | 'connecting' | 'connected' | 'paused' | 'errored'
    >('disconnected')
    const [pins, setPins] = useState<Feature<Point>[] | undefined>(undefined)
    const reporter = useErrorReporterContext()
    const visible = useVisible()

    useDebugValue(!pins?.length ? 'Empty' : `${pins.length} Pins`)
    useDebugValue(status)

    /**
     * Maps a section of an incoming set of property events into
     * {@link Feature OpenLayers Features}. Execution is deferred to the next
     * event loop tick.
     *
     * @param pins the full set of property events received.
     * @param batchStart the index of the first property event in the batch.
     * @param batchEnd the index of the last property event in the batch.
     * @param now the current time, in ms.
     *
     * @returns the set of features corresponding to the batch.
     */
    const handlePropertyEventBatch: (
        pins: PropertyEvent[],
        batchStart: number,
        batchEnd: number,
        now: number
    ) => Promise<Feature<Point>[]> = useMemo(
        () =>
            nextIdle(
                checkPerformance(
                    'handlePropertyEventBatch',
                    function handlePropertyEventBatch(
                        pins: PropertyEvent[],
                        batchStart: number,
                        batchEnd: number,
                        now: number
                    ) {
                        const chunkPins: Feature<Point>[] = []
                        batchEnd = Math.min(batchEnd, pins.length)
                        let numStalePins = 0

                        for (let i = batchStart; i < batchEnd; i++) {
                            const { lat, long, timestamp, ...rest } = pins[i]
                            const coordinates: LongLat = [long, lat]
                            const projectedCoordinates = fromLonLat(coordinates)
                            // Timestamps are relative. That is, the first pin has a timestamp of 0,
                            // and all other pins are offset from this. This allows for
                            // network latency resilience.
                            const delayedTimestamp = now + timestamp + delay

                            // Property event already occurred, even with delay, which
                            // causes pins to flash into existence.
                            if (delayedTimestamp <= now) {
                                numStalePins += 1
                            } else {
                                // Create a new feature for this event and add it to the chunk.
                                chunkPins.push(
                                    new Feature<Point>({
                                        ...rest,
                                        timestamp: delayedTimestamp,
                                        geometry: new Point(projectedCoordinates),
                                    })
                                )
                            }
                        }

                        // Report the number of stale pins if there are any
                        if (numStalePins > 0) {
                            reporter.warn(
                                `Received ${numStalePins} stale property events from event ` +
                                    'batch. This may cause visual glitches such as pins ' +
                                    'suddenly appearing.',
                                {
                                    numStalePins,
                                    batchSize: batchEnd - batchStart,
                                }
                            )
                        }

                        return chunkPins
                    },
                    reporter
                )
            ),
        [delay, reporter]
    )

    const setPinsDelayed = useMemo(
        () =>
            nextTick(function _setPins(pinChunk: Feature<Point>[]) {
                // Don't dispatch pin chunk if it's empty. Sending, receiving, and
                // responding to these events is expensive.
                pinChunk?.length && setPins(pinChunk)
            }),
        [setPins]
    )

    const handlePropertyEvents = useCallback(
        async function handleSearchBatch(pins: PropertyEvent[]) {
            debug('Received %d property events', pins.length)

            const now = Date.now()
            const batchSize = Math.ceil(Math.pow(pins.length, 0.75))

            for (let i = 0; i < pins.length; i += batchSize) {
                await handlePropertyEventBatch(pins, i, i + batchSize, now).then(setPinsDelayed)
                // await setPinsDelayed(pinChunk)
                // const pinChunk = await handlePropertyEventBatch(pins, i, i + batchSize, now)
                // await setPinsDelayed(pinChunk)
            }

            // setPins(pinFeatures)
        },
        [handlePropertyEventBatch, setPinsDelayed]
    )

    const initializeSocket = () => {
        if (!socket?.current) {
            debug(`Connecting to backend socket at ${uri}`)
            const s = (socket.current = io(uri))
            setStatus('connecting')

            s.on('connect', () => {
                debug('Connection established')
                setStatus('connected')
            })

            s.on('disconnect', () => {
                debug('socket.io connection disconnected')
                setStatus('disconnected')
            })

            s.on('reconnect', n => {
                if (typeof n === 'number') {
                    debug(`reconnected after ${n} tries`)
                } else {
                    debug('reconnected')
                }
                setStatus('connected')
            })

            s.on('reconnecting', () => {
                debug('reconnecting...')
                setStatus('connecting')
            })

            s.on('reconnect_error', err => {
                err.message = 'reconnection error: ' + err.message
                reporter.error(err)
                setStatus('errored')
            })

            s.on('reconnect_failed', () => {
                reporter.error('Failed to reconnect to backend')
                setStatus('errored')
            })

            s.on('connect_error', err => {
                err.message = 'Failed to connect to backend: ' + err.message
                reporter.error(err)
                setStatus('errored')
            })

            s.on('error', err => {
                reporter.error(err)
                setStatus('errored')
            })
            s.on(SEARCHES_BATCH_KEY, handlePropertyEvents)
        }
    }

    const stopSocket = useCallback(() => {
        if (socket?.current && socket.current.hasListeners(SEARCHES_BATCH_KEY)) {
            debug('Stopping socket')
            socket.current.off(SEARCHES_BATCH_KEY, handlePropertyEvents)
            setStatus('paused')
        }
    }, [handlePropertyEvents])

    const startSocket = useCallback(() => {
        if (socket?.current && !socket.current.hasListeners(SEARCHES_BATCH_KEY)) {
            debug('Starting socket')
            socket.current.on(SEARCHES_BATCH_KEY, handlePropertyEvents)
            setStatus('connected')
        }
    }, [handlePropertyEvents])

    useEffect(() => {
        if (visible) {
            startSocket()
        } else {
            stopSocket()
        }
    }, [visible, startSocket, stopSocket])

    useMount(() => {
        debug('Establishing socket.io connection to %s', uri)
        initializeSocket()
    })

    return pins
}
